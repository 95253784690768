import axios from 'axios'
import store from '@/store/index'

if (store.state.token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`
}
axios.defaults.withCredentials = true

const instance = axios.create({
  // baseURL: 'http://127.0.0.1:11080', //docker
  baseURL: 'http://127.0.0.1:8888',
  //baseURL: 'https://threelabs-api.onoffmix.xyz:11443/',
  //baseURL: 'http://test-marketbang-462608889.ap-northeast-2.elb.amazonaws.com',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    // 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  },
})

instance.interceptors.request.use(
  (config) => {
    config.baseURL = (() => {
      let return_str = '/'
      if (
        window.location.origin.indexOf('localhost') > -1 ||
        window.location.origin.indexOf('127.0.0.1') > -1
      ) {
        return config.baseURL
      } else {
        if (window.location.origin.indexOf('seller.') > -1) {
          return_str = window.location.origin.replace('seller.', 'api.')
        } else if (window.location.origin.indexOf('partner.') > -1) {
          return_str = window.location.origin.replace('partner.', 'api.')
        } else if (window.location.origin.indexOf('sell.') > -1) {
          return_str = window.location.origin.replace('sell.', 'api.')
        }
        return return_str
      }
    })()
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

let show_notice_alert = false;
instance.interceptors.response.use(
  (response) => { return response },
  (error) => {
    if (error.response.status === 401 && error.response.data.code === 401) {
      window.location.href = "/login?return=" + window.location.pathname;
    }else if (error.response.status === 503 && error.response.data.code === 9001) {
			let message = error.response.data.message;
			message = message.replaceAll('\\r\\n', '\r\n');
      if(!show_notice_alert){
        show_notice_alert = true;
        alert(message);
        setTimeout(() => {show_notice_alert = false;}, 2000);
      }
    }
    return Promise.reject(error);
  },
)

export default instance
